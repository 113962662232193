.RichTextEditor [class~='ql-editor'] {
	background-color: var(--bg-white);
	font-size: 1rem;
	font-family: var(--font);
	transition: box-shadow 0.2s ease-in-out;
}

.Focus [class~='ql-editor'] {
	box-shadow: inset 0px 0px 0px 1px #999;
}
