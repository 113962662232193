.AppInputContainer {
	display: flex;
	flex-direction: column;
}

.AppInputContainer:not(:first-child) label {
	margin-top: 2rem;
}

.AppInputLabel {
	margin-bottom: 0.5rem;
}

.AppInput {
	padding: 0.5em 1em;
	font-size: 1rem;
	outline: none;
	border: var(--border-grey);
	border-radius: 4px;
	transition: border-color 0.1s ease;
	font-family: var(--font);
}

.AppInput:focus {
	border-color: var(--color-accent-teal);
}
