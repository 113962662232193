.SidePanelPageMask {
	z-index: 2;
	visibility: hidden;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	transition:
		background-color 0.3s ease-in-out,
		visibility 0s 0.3s;
}

.SidePanelPageMaskActive {
	visibility: visible;
	background-color: rgba(0, 0, 0, 0.6);
	transition: background-color 0.3s ease-in-out;
}

.SidePanel {
	z-index: 2;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	width: var(--sidePanel-size);
	background-color: var(--bg-default);
	transform: translateX(100%);
	transition: transform 0.3s ease-in-out;
	display: flex;
	flex-direction: column;
}

.SidePanelOpen {
	visibility: visible;
	transform: translateX(0);
}

.SidePanelWide {
	width: calc(100% - var(--sideBar-width)) !important;
}

.SidePanelHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: var(--navBar-height);
	padding: 0 var(--default-padding);
	background-color: var(--bg-white);
}

.SidePanelOpen .SidePanelHeader {
	box-shadow: var(--navBar-box-shadow);
}

.SidePanelHeader svg {
	color: var(--text-color);
}
.SidePanelHeader h1 {
	margin-bottom: 0.2em;
}

.SidePanelCloseButton {
	cursor: pointer;
}

.SidePanelContent {
	padding: var(--default-padding);
	color: var(--text-color);
	overflow: auto;
}

.SidePanelContent img {
	background-size: 100%;
}
