.TableButtonsContainer {
	display: flex;
	justify-content: flex-end;
}

.TableIcon {
	color: var(--text-color);
}

.TableActionButton {
	cursor: pointer;
	color: var(--text-color);
}

.SmallTh {
	padding: 0;
	width: 1.5rem;
}
