.AppIcon {
	display: flex;
	align-items: center;
	font-style: normal;
}

.AppIconSpaceBetween {
	justify-content: space-between;
}

.AppIconSpaceBetween svg {
	padding: 0 !important;
}

/* --- default --- */

.AppIcon svg {
	width: 25px;
	height: 25px;
}

.AppIconRight svg {
	padding-left: 1em;
}

.AppIconLeft svg {
	padding-right: 1em;
}

/* --- xs --- */

.AppIconXs svg {
	width: 15px;
	height: 15px;
}

.AppIconRight.AppIconXs svg {
	padding-left: 0.2em;
}

.AppIconLeft.AppIconXs svg {
	padding-right: 0.2em;
}

/* --- sm --- */

.AppIconSm svg {
	width: 20px;
	height: 20px;
}

.AppIconLeft.AppIconSm svg {
	padding-right: 0.5em;
}

.AppIconRight.AppIconSm svg {
	padding-left: 0.5em;
}

/* --- lg --- */

.AppIconLg svg {
	width: 30px;
	height: 30px;
}

.AppIconLeft.AppIconLg svg {
	padding-right: 1em;
}

.AppIconRight.AppIconLg svg {
	padding-left: 1em;
}
