.PlanOverview {
	padding: var(--default-padding);
}

.PlanOverviewRoadmap {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.PlanOverviewSegments {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
	grid-gap: var(--default-padding);
}

.PageLoading {
	padding: var(--default-padding);
}

.PhaseContainer {
	display: flex;
	flex-direction: column;
}

.PhaseSegments {
	background-color: #fff;
	border-radius: 0.2em;
	box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
	padding-bottom: 1em;
	width: 100%;
	flex: 1;
}

table.Table {
	box-shadow: none;
	padding: 0;
	margin-top: 0;
	border-spacing: 0;
}

table.Table tbody tr {
	transition: background 0.1s ease-in-out;
}

table.Table tbody tr:hover {
	background: #efefef;
	cursor: pointer;
}
