@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

@font-face {
	font-family: 'oranda-bt-bold';
	src:
		local('oranda-bt-bold'),
		url('./fonts/oranda-bold-bt.ttf') format('truetype');
}

@font-face {
	font-family: 'oranda-bt';
	src:
		local('oranda-bt'),
		url('./fonts/oranda-bt.ttf') format('truetype');
}
