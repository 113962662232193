.Pagination {
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;
	width: 600px;
	float: right;
}

.PageItem {
	display: flex;
	flex: 1;
	margin-left: -1px;
}

.PageLink {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;

	padding: 0.5rem 0.8rem;

	background-color: #fff;
	color: var(--color-accent-teal);
	text-decoration: none;

	cursor: pointer;
	border: 1px solid #dee2e6;
	transition:
		color 0.15s ease-in-out,
		background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
}

.PageLink:hover {
	background-color: var(--bg-default);
}

.Active .PageLink {
	color: var(--color-accent-white);
	background-color: var(--color-accent-teal);
	border-color: var(--color-accent-teal);
}

.DisabledPageItem {
	opacity: 0.3;
}

.DisabledPageLink {
	cursor: not-allowed;
}
