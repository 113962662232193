/* --- SubGoalDescription --- */
.Container {
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
}

.Container h1 {
	margin-bottom: 0;
}

.SubGoalDescription {
	margin-bottom: 2em;
}

.SubGoalDescriptionTitleContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.SubGoalDescriptionTitleContainer h2 {
	font-size: 24px;
}

.SubGoalDescriptionButtons {
	display: flex;
	gap: 2em;
}
