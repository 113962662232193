.DropdownContainer,
.DropdownContainer * {
	box-sizing: border-box;
	position: relative;
}

.DropdownButton {
	-webkit-appearance: none;
	background-color: transparent;
	border: 0;
	border-bottom: 1px solid #585858;
	cursor: pointer;
	display: inline-block;
	height: 2.9rem;
	line-height: 2.9rem;
	/* margin: 0 0 0.94rem; */
	outline: none;
	padding: 0 25px 0 1em;
	text-align: left;
	text-decoration: none;
	text-overflow: ellipsis;
	width: 100%;
	box-sizing: border-box;
	font-feature-settings: 'liga';
	color: #212121;
	font-family:
		Source Sans Pro,
		sans-serif;
	overflow: visible;
	font-size: 1em;
}

.DropdownButton svg {
	position: absolute;
	color: #157c68;
	right: 15px;
	top: 1rem;
}

ul.SelectList {
	display: block;
	background-color: #fff;
	box-shadow:
		0 2px 5px 0 rgb(0 0 0 / 16%),
		0 2px 10px 0 rgb(0 0 0 / 12%);
	margin: 0;
	max-height: 40.625rem;
	min-width: 6.25rem;
	overflow-y: auto;
	position: absolute;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: 100%;
	will-change: width, height;
	z-index: 999;
	transition: opacity 0.3s ease-out;
	opacity: 1;
	list-style: none;
	padding: 0;
}

ul.SelectList.Hidden {
	opacity: 0;
	max-height: 0rem;
}

.SelectList[role='option'] {
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
	clear: both;
	color: #212121;
	cursor: pointer;
	display: block;
	font-size: 1rem;
	line-height: 1.3rem;
	padding: 1rem;
	text-align: left;
	text-transform: none;
	width: 100%;
}

.SelectList[role='option']:hover {
	background: rgba(0, 168, 137, 0.1);
}

.SelectList[role='option'].Focused {
	background: rgba(0, 168, 137, 0.2);
}
