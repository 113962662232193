.AppCheckboxContainer {
	display: flex;
	flex-direction: column;
}

.AppCheckboxContainer:not(:first-child) label {
	margin-top: 2rem;
}

.AppCheckboxWrapper {
	display: flex;
	align-items: center;
	margin-top: 0.8rem;
	font-size: 1rem;
	cursor: pointer;
}

.HiddenCheckbox {
	-webkit-appearance: none;
	appearance: none;
	width: 0;
	height: 0;
	margin: 0;
	background-color: var(--bg-white);
}

.AppCheckbox {
	display: flex;
	justify-content: center;
	align-items: center;

	height: 20px;
	width: 20px;
	border: var(--border-grey);
	border-radius: 4px;
}

.AppCheckboxWrapper:hover {
	filter: brightness(1.2);
}

.AppCheckboxWrapper:hover .CheckMark:not(.Checked) {
	opacity: 0.3;
}

.AppCheckboxWrapper:hover .CheckMark.Checked {
	opacity: 0.7;
}

.CheckMark {
	color: var(--color-accent-teal);
	padding: 2px;
	opacity: 0;
	transition: opacity 20ms ease-in-out;
}

.Checked {
	opacity: 1;
}

.AppCheckboxText {
	margin-left: 0.7em;
}

/*.AppCheckbox::before {*/
/*  position: relative;*/
/*  width: 10px;*/
/*  height: 10px;*/
/*  left: calc(50% - 10px);*/
/*  content: '\2713';*/
/*  text-align: center;*/

/*}*/

/*.AppCheckbox:checked::before {*/
/*}*/

.AppCheckbox.AppCheckboxLg {
	height: 25px;
	width: 25px;
}
