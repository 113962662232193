.SideBar {
	padding: var(--default-padding);
	background-color: #3a3a3a;
}

.SideBar h1 {
	color: #fff;
}

.SideBar p {
	color: #fff;
}

.SideBarPlan {
	border-top: var(--border-grey);
}

.SideBarButtonContainer {
	padding: 0 var(--default-padding);
}

.SideBarButton {
	min-width: 60%;
}

.SideBarButton svg {
	fill: var(--color-accent-white);
}

/* --- Collapse --- */

.Collapse {
	color: #fff;
}

.DisabledTransition {
	transition: none !important;
}

.Collapsed {
	max-height: 0;
	transform: scaleY(0);
	transform-origin: top;
	transition:
		transform 200ms ease,
		max-height 200ms ease-out;
}

.Extended {
	height: auto;
	max-height: 1000px;
	transform: scaleY(1);
	transition:
		transform 400ms ease,
		max-height 800ms ease-out;
}

/* --- CollapseHead --- */

.CollapseHead {
	padding: 1.2em var(--default-padding);
	border-bottom: var(--border-grey);
	font-family: var(--font-accent);
	font-weight: bold;
	transition: filter 300ms;
	text-transform: uppercase;
}

.CollapseHead:hover {
	cursor: pointer;
	filter: brightness(1.1);
}

.CollapseHead svg {
	fill: var(--text-color-dark-bg);
	transition: transform 200ms;
}

.CollapseHeadOpen {
	margin-top: -1px;
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
	background-color: var(--color-accent-red);
	color: var(--color-accent-white);
}

.CollapseHeadDisabled:hover {
	cursor: unset;
	filter: none;
}

.Rotate {
	transform: rotate(90deg);
}

/* --- CollapseItem --- */

.CollapseItem {
	padding: 1.2em var(--default-padding);
	border-bottom: var(--border-grey);
}

.CollapseItemOpen {
	color: var(--text-color);
	border-bottom: var(--border-grey);
	background-color: var(--bg-default);
}

.CollapseItemOpenActive {
	font-weight: bold;
}

.DownloadButtonIcon {
	margin-left: 1em;
}

.DownloadButtonLoader {
	margin: auto;
}

.LoadingButton {
	min-width: 60%;
}

.LoadingButton,
.LoadingButton * {
	cursor: not-allowed !important;
}
