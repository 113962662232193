.AppButton {
	padding: 0.8rem 1.2rem;
	margin: var(--default-padding) 0;
	background-color: var(--color-accent-teal-button);

	color: var(--color-accent-white);
	font-size: unset;
	font-family: var(--font-accent);
	font-weight: bold;

	border-radius: 3px;
	box-shadow:
		rgba(0, 0, 0, 0.16) 0 3px 6px,
		rgba(0, 0, 0, 0.23) 0 3px 6px;
	border-style: none;

	transition:
		filter 300ms,
		box-shadow 200ms ease-in-out;
}

.AppButton:hover:not(:disabled) {
	cursor: pointer;
	filter: brightness(1.1);
}

.AppButton:disabled {
	background-color: var(--color-disabled-grey);
	box-shadow: unset;
}

.AppButton:active {
	box-shadow: unset;
	transition: box-shadow 200ms ease;
}

.AppButtonSm {
	padding: 0.5rem 0.8rem;
}

.AppButtonLg {
	padding: 1rem 1.4rem;
}

.AppButtonNoMargin {
	margin: 0;
}

.AppButtonWhite {
	background-color: var(--bg-white);
	color: var(--color-accent-teal);
}

.AppButtonFlat {
	border: var(--border-grey);
	box-shadow: none;
	font-weight: normal;
}

.AppButtonFlat:hover:not(:disabled) {
	filter: initial;
}

.NoBackground {
	background-color: transparent;
	color: var(--color-accent-teal);
	border: none;
	padding: none;
}

.NoBackground:hover {
	text-decoration: underline;
}
