.ProgressBar {
	width: 100%;
	height: 20px;
	background-color: var(--text-color);
	border-radius: 5px;
}

.ProgressBarFiller {
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
	height: 100%;
	width: 0;
	background-color: var(--color-accent-red);
	border-radius: inherit;
}

.ProgressBar span {
	margin-right: 5px;
	color: var(--text-color-dark-bg);
}
