.TableButtonsContainer {
	display: flex;
	justify-content: flex-end;
}

.TableIcon {
	color: var(--text-color);
}

.TableActionButton {
	cursor: pointer;
	color: var(--text-color);
}

/* ===== Below here is all duplicated css. @Michiel you probbaly know how to prevent this. ===*/

.ProcessGoalDescriptionContainer {
	display: grid;
	grid-gap: 2em;
	grid-template-columns: auto var(--sideBar-width);
	grid-template-areas: 'main infobar';
}

/* --- SubGoalDescription --- */

.SubGoalDescription {
	margin-bottom: 2em;
}

.SubGoalDescriptionTitleContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1em;
}

.SubGoalDescriptionButtons {
	display: flex;
	gap: 2em;
}

/* --- InfoBar --- */

.InfoBar {
	margin-top: 1.5rem;
	padding: 1.2rem 1rem;
	background-color: var(--bg-white);
}

.InfoBar svg {
	fill: var(--color-accent-red);
}

.InfoBar h2 {
	color: var(--color-accent-red);
}

.InfoBarLinksContainer {
	padding-bottom: 2em;
}

.InfoBarLink {
	padding: 10px 10px;
	border-bottom: var(--border-grey);
	color: var(--color-accent-teal);
}

.InfoBarLink a {
	color: var(--color-accent-teal);
	text-decoration: underline;
}

.InfoBarLink svg,
.InfoIcon {
	fill: var(--color-accent-teal);
}

.InfoIcon {
	fill: var(--color-accent-teal);
	color: var(--color-accent-teal);
}

.InfoBarImage img {
	width: 100%;
}
