:root {
	--bg-default: #f5f5f5;
	--bg-white: white;
	--bg-grey: grey;
	--bg-dark-grey: dimgrey;

	--color-accent-red: #a80a2d;
	--color-accent-white: whitesmoke;
	--color-accent-teal: #17564a;
	--color-accent-teal-button: #157c68;
	--color-disabled-grey: #98adad;

	--font: 'Source Sans Pro';
	--font-accent: system-ui;
	--text-color: #6b6b6b;
	--text-color-dark-bg: #ced4da;

	--sideBar-width: 25%;
	--sidePanel-size: 35%;
	--navBar-height: 80px;
	--step-navbar-height: 80px;
	--default-padding: 2rem;

	--border-white: 1px solid whitesmoke;
	--border-light-grey: 1px solid #dddddd;
	--border-grey: 1px solid darkgray;

	--navBar-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
}

body,
html {
	height: 100%;
	width: 100%;
	margin: 0;
	background-color: var(--bg-default);
	font-family: var(--font);
	color: var(--text-color);
}

#root {
	height: 100%;
	width: 100%;
}

p,
span {
	line-height: 1.6;
}

h1,
.h1-style {
	color: var(--color-accent-red);
	font-family: 'oranda-bt', sans-serif;
	font-weight: normal;
	font-size: 2.25em;
	margin-block-end: 0.6125em;
	margin-top: 0;
}

h2 {
	color: var(--text-color);
	font-family: 'oranda-bt', sans-serif;
	font-weight: normal;
	font-size: 1.875em;
}

a {
	color: var(--text-color);
	text-decoration: none;
}

input {
	box-sizing: border-box;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

section {
	padding-bottom: var(--default-padding);
}

section:not(:last-child) {
	border-bottom: var(--border-light-grey);
}

table {
	width: 100%;
	margin-top: 1em;
	padding-bottom: 1em;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.16) 0 2px 6px;
	border-radius: 0.2em;
}

table th {
	text-align: left;
	padding: 0 20px;
	font-size: 1.5em;
	border-bottom: var(--border-white);
	font-family: 'oranda-bt', sans-serif;
	font-weight: normal;
	height: 50px;
	line-height: 1.6;
}

table td {
	padding: 0.5em 20px;
	border-bottom: var(--border-white);
	color: var(--color-accent-teal);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

table td a {
	color: var(--color-accent-teal);
	text-decoration: underline;
}
