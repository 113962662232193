.StatusIconVerified {
	color: var(--color-accent-teal-button);
}

.StatusIconWaiting {
	color: var(--color-accent-red);
}

.StatusIconNoStatus {
	color: var(--text-color);
}
