.Brand {
	display: flex;
	align-items: center;
	padding: 0 var(--default-padding);
	background-color: var(--bg-dark-grey);
	color: #fff;
}

.Brand title {
	color: #fff;
}

.Brand svg {
	object-fit: contain;
	width: 100%;
}

.Brand span {
	font-size: 1.125em;
	text-transform: uppercase;
	color: #fff;
	margin-left: 1.25em;
	pointer-events: none;
	margin-top: 0.5rem;
}

.Brand span:first-of-type {
	margin-top: 0.3rem;
}
