.TableAddSubStepButton {
	/* padding: td left + right padding */
	padding: 1rem;
	font-family: var(--font-accent);
	font-weight: bold;
	border: 2px dotted #98adad68;
	cursor: pointer;
}

.TableAddSubStepButton svg {
	height: 0 !important;
}

.SubstepMenuContainer {
	display: inline-block;
	width: 100%;
	margin-bottom: 2em;
}

.SubstepMenuContainer ul {
	display: grid;
	grid-template-columns: repeat(5, auto) 1fr;
}

.SubstepFormContainer,
.SubstepFormContainer *,
.SubstepFormContainer label {
	font-family: 'oranda-bt', sans-serif;
}
.SubstepFormContainer label {
	font-size: 1.5em;
}

li.SubstepMenuItem a {
	display: flex;
	align-items: center;
	float: left;
	height: 46px;
	background: #efefef;
	text-align: center;
	padding: 0 33px;
	position: relative;
	margin: 0 4px 0 0;

	font-size: 18px;
	text-decoration: none;
	color: #212121;
	box-sizing: border-box;
	cursor: pointer;
}

li.SubstepMenuItem a:after {
	content: '';
	border-top: 23px solid transparent;
	border-bottom: 23px solid transparent;
	border-left: 13px solid #efefef;
	position: absolute;
	right: -13px;
	top: 0;
	z-index: 1;
}

li.SubstepMenuItem a:before {
	content: '';
	border-top: 23px solid transparent;
	border-bottom: 23px solid transparent;
	border-left: 13px solid #f5f5f5; /* Form background*/
	position: absolute;
	left: 0;
	top: 0;
}

li.SubstepMenuItem:first-child a {
	padding: 0 20px;
}

li.SubstepMenuItem:first-child a::before {
	display: none;
}

li.SubstepMenuItem:last-child a {
	padding-right: 25px;
	width: 100%;
}

li.SubstepMenuItem:last-child a::after {
	display: none;
}

li.ActiveSubstepMenuItem a {
	background: #a80a2d;
	color: #fff;
}

li.ActiveSubstepMenuItem a::after {
	border-left-color: #a80a2d;
}

li.SubstepMenuItem span.Circle {
	background-color: #fff;
	width: 24px;
	height: 24px;
	border-radius: 12px;
	color: #a80a2d;
	display: flex;
	align-items: center;
	margin-right: 10px;
}
li.SubstepMenuItem span.Circle svg {
	margin: auto;
}

.SubStepButtons button {
	float: right;
	display: inline;
}

.SubStepButtons button.StepperButton {
	float: left;
	margin-right: 20px;
}

.SubStepMandateRow {
	display: grid;
	grid-template-columns: 2fr 2fr 1fr;
}

.StakeholderGroups {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	background-color: #fff;
	box-shadow: rgb(0 0 0 / 16%) 0 2px 6px;
	padding-bottom: 1em;
	border-radius: 0.2em;
}

.StakeholderGroups > div {
	padding: 0.5em;
	border-width: 0;
	border-bottom: var(--border-white);
	font-family:
		Source Sans Pro,
		sans-serif;
}

.StakeholderGroups > div * {
	font-family:
		Source Sans Pro,
		sans-serif;
}

.StakeholderGroups > div.HeaderRow {
	font-weight: normal;
	cursor: default;
	font-size: 1.5em;
	font-family: 'oranda-bt', sans-serif;
}

.StakeholderGroups > div.Name {
	cursor: default;
}

.StakeholderGroups > div.EmptyRow {
	grid-column: 1/-1;
}
