/* --- Collapse --- */

.Accordion {
	color: var(--text-color-dark-bg);
	/* margin-top: var(--default-padding); */
}

.DisabledTransition {
	transition: none !important;
}

.Collapsed {
	max-height: 0;
	transform: scaleY(0);
	transform-origin: top;
	transition:
		transform 200ms ease,
		max-height 200ms ease-out;
}

.Extended {
	height: auto;
	max-height: 1000px;
	transform: scaleY(1);
	transition:
		transform 400ms ease,
		max-height 800ms ease-out;
}

/* --- CollapseHead --- */

.AccordionHeader {
	padding: 1.2em var(--default-padding);
	border-bottom: var(--border-grey);
	font-family: var(--font-accent);
	font-weight: bold;
	transition: filter 300ms;
	text-transform: uppercase;
	box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
	background-color: white;
	color: var(--color-accent-teal);
}

.AccordionHeader:hover {
	cursor: pointer;
	filter: brightness(1.1);
}

.AccordionHeader svg {
	fill: var(--text-color-dark-bg);
	transition: transform 200ms;
}

.AccordionHeaderOpen {
	margin-top: -1px;
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
	background-color: var(--color-accent-red);
	color: var(--color-accent-white);
}

.AccordionHeaderDisabled:hover {
	cursor: unset;
	filter: none;
}

.Rotate {
	transform: rotate(90deg);
}

/* --- CollapseItem --- */

.ShowStyling .AccordionContent {
	padding: 1.2em var(--default-padding);
	border-bottom: var(--border-grey);
	color: var(--text-color);
	background-color: white;
	box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
}

.ShowStyling .AccordionContentOpen {
	color: var(--text-color);
	border-bottom: var(--border-grey);
	background-color: var(--bg-default);
}

.ShowStyling .AccordionContentOpenActive {
	font-weight: bold;
}
