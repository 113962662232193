.SearchButton {
	display: block;
	position: absolute;
	right: 8px;
	top: 50%;
	transform: translateY(-50%);
	width: 17.5px;
	height: 17.5px;
	background: url('../../icons/magnify.svg') no-repeat;
	cursor: pointer;
}

.Container {
	position: relative;
}
