.SegmentPageWrapper {
	padding: var(--default-padding);
	margin-bottom: var(--step-navbar-height);
}

.InfoGrid {
	display: grid;
	grid-gap: 2em;
	grid-template-columns: auto var(--sideBar-width);
	grid-template-areas: 'main infobar';
	width: 100%;
}

/* --- SegmentNavigationBar --- */

.SegmentNavigationBar {
	position: fixed;
	bottom: 0;
	right: 0;
	left: var(--sideBar-width);

	height: var(--step-navbar-height);
	padding: 0 2em;
	background-color: var(--bg-white);
}

.SegmentNavigationBarButtons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
}
/* --- InfoBar --- */

.InfoBar {
	margin-top: 0.7rem;
	padding: 1.2rem;
	background-color: var(--bg-white);
}

.InfoBar svg {
	fill: var(--color-accent-red);
}

.InfoBar h2 {
	color: var(--color-accent-red);
	font-size: 24px;
	margin-bottom: 14px;
}

.InfoBarLinksContainer {
	padding-bottom: 2em;
}

.InfoBarLink {
	padding: 10px 10px;
	border-bottom: var(--border-grey);
	color: var(--color-accent-teal);
}

.InfoBarLink a {
	color: var(--color-accent-teal);
	text-decoration: underline;
}

.InfoBarLink svg,
.InfoIcon {
	fill: var(--color-accent-teal);
}

.InfoIcon {
	fill: var(--color-accent-teal);
	color: var(--color-accent-teal);
}

.InfoBarImage img {
	width: 100%;
	cursor: pointer;
}

.InfoBarImagePopup {
	height: auto;
}

.PopupImage {
	position: relative;
	margin-top: 10px;
}

.PopupImage::after {
	display: block;
	content: ' ';
	position: absolute;
	width: 20px;
	height: 20px;
	padding: 15px;
	bottom: 0;
	right: 0;
	background-image: url('../../icons/resize.svg');
	background-color: #000000b3;
	background-repeat: no-repeat;
	background-position: 50%;
	pointer-events: none;
}

.PopupImage:hover::after {
	background-color: #000000e0;
}

.Header {
	display: grid;
	grid-template-columns: 1fr max-content;
	border-bottom: var(--border-light-grey);
	padding-bottom: 0.8rem;
	margin-bottom: 0.8rem;
}

.Header > div:last-child {
	grid-column: 1 / -1;
}
