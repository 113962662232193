.NavBar {
	display: flex;
	justify-content: space-between;
	background-color: white;
	box-shadow: var(--navBar-box-shadow);
}

.NavBar ul {
	height: 100%;
}

.NavBar ul li {
	height: 100%;
	float: left;
}

.NavBar a {
	display: flex;
	align-items: center;
	height: 100%;
	margin-left: 1.5em;
	padding: 0 1em;
	border: solid 1px transparent;
	color: var(--color-accent-red);
	font-size: 1.25em;
}

.NavBar a:hover {
	border: var(--border-grey);
}

.NavBarItemsContainer {
	height: 100%;
}

.NavBarUserContainer {
	display: flex;
	align-items: center;
	margin-right: 2em;
}

.NavBarUser {
	padding: 10px;
	border: var(--border-grey);
	border-radius: 5px;
}
