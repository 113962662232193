.ToolTip {
	cursor: help;
}

.ToolTip button,
.ToolTipContainer {
	cursor: help !important;
}

.ToolTipContainer {
	max-width: 20%;
}

.Inline {
	display: inline-block;
}

.OnlyIcon {
	position: absolute;
	margin-left: 0.5rem;
	margin-top: 0.3em;
}

div.Clickable.ToolTip,
.Clickable.ToolTip button {
	cursor: pointer !important;
}
