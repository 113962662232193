.Breadcrumb {
	/* padding-bottom: 1rem; */
}

.Breadcrumb a:not(:last-child) {
	color: var(--color-accent-teal);
	text-decoration: underline;
}

.Breadcrumb a:last-child {
	pointer-events: none;
}
