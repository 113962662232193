.Dashboard {
	padding: var(--default-padding);
}

.Dashboard table {
	table-layout: fixed;
	border-spacing: 0;
	margin-top: 2.5rem;
}

.Dashboard table tbody tr {
	transition: background 0.1s ease-in-out;
}

.Dashboard table tbody tr:hover {
	background-color: #efefef;
	cursor: pointer;
}

.Dashboard label {
	display: inline-block;
	margin-bottom: 0.5rem;
}

.Dashboard .AccountOutlined {
	color: #ced4da;
}

th.Small {
	width: 150px;
}

th.Medium {
	max-width: 300px;
}

th.Large {
	max-width: auto;
}

.TableButtonsContainer {
	display: flex;
	justify-content: flex-end;
	margin-left: auto;
}

.TableActionButton {
	color: var(--text-color);
	cursor: pointer;
}
