.ProcessOverview {
	padding: var(--default-padding);
}

.ProcessOverviewRoadmap {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.PageLoading {
	padding: var(--default-padding);
}

/* --- Roadmap --- */

.Roadmap h2,
.Roadmap h3 {
	font-family: var(--font-accent);
	color: var(--color-accent-red);
	text-transform: uppercase;
	font-size: 1.1em;
	font-weight: bold;
}

.Roadmap ul {
	margin-left: 0.5em;
	padding-left: 0.5em;
	border-left: 1px solid var(--text-color-dark-bg);
}

.Roadmap li {
	margin-bottom: 0.8em;
	position: relative;
	display: inline-flex;
	align-items: center;
	margin-left: 0.5em;
}

.Roadmap li:last-child {
	margin-bottom: -2em;
}

.Roadmap li.Active a {
	background: var(--color-accent-teal-button);
	color: #fff;
	text-decoration: none;
}

.Roadmap li.Active::before {
	background: var(--color-accent-teal-button);
}

.Roadmap li a {
	text-decoration: underline;
	padding: 0.5em;
	font-size: 0.9em;
}

.Roadmap ul li::after {
	content: '';
	position: absolute;
	width: 1em;
	height: 1em;
	background: var(--color-accent-teal-button);
	border-radius: 1em;
	left: -1.5em;
}

.Roadmap ul li::before {
	content: '';
	position: absolute;
	width: 0.5em;
	height: 2px;
	background: var(--text-color-dark-bg);
	left: -0.5em;
}

.Roadmap .Header {
	display: grid;
	grid-template-columns: min-content auto;
	align-items: center;
	padding-right: 0.8em;
	overflow-x: hidden;
}

.Roadmap .Header h2,
.Roadmap .Header h3 {
	display: inline-block;
	width: max-content;
	padding-right: 0.3em;
}

.Roadmap .Header span {
	height: 2px;
	background: var(--text-color-dark-bg);
	position: relative;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Roadmap .Header span svg {
	background: var(--text-color);
	color: #fff;
	border-radius: 0.5em;
}

.ProcessOverviewRoadmap .Roadmap:last-child .Header span {
	background: transparent;
}

.ProcessOverviewRoadmap .Roadmap:last-child .Header span svg {
	display: none;
}

.RoadmapAccordion {
	margin-top: 0;
}
